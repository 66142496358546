import { defineStore } from 'pinia'
import { useNuxtApp } from '#app'
const nuxtApp = useNuxtApp()

export const useContactsStore = defineStore('contacts', {
    state: () => ({
        contacts: [], // contacts with relations
        contactList: [], // just the contacts
        shoppingCart: []
    }),

    actions: {
        fetchContacts (params = { force: false }) {
            if (this.contacts.length && !params.force) {
                return Promise.resolve(this.contacts)
            }

            nuxtApp.$contactRepository.index({
                with: [
                    'products', 'clients', 'banks', 'funderCompanies',
                    'partnerCompanies', 'comments', 'tasks', 'contactEvents',
                    'opsStaff', 'salesStaff', 'user'
                ]
            })
                .then((response) => {
                    this.setContacts(response.data)
                })
        },
        fetchContactList (params = { force: false }) {
            if (this.contactList.length && !params.force) {
                return Promise.resolve(this.contactList)
            }

            return nuxtApp.$contactRepository.index()
                .then((response) => {
                    this.setContactList(response.data)
                })
        },
        setContacts (contacts) {
            this.contacts = contacts
        },
        setContactList (contacts) {
            this.contactList = contacts
        },
        addToShoppingCart (contact) {
            if (this.shoppingCart.filter(s => s.id === contact.id).length) {
                return null
            }
            this.shoppingCart.push(contact)
        },
        resetShoppingCart () {
            this.shoppingCart = []
        },
        removeFromShoppingCart (contact) {
            this.shoppingCart = this.shoppingCart.filter(c => c.id !== contact.id)
        }
    }
})
